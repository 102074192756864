import React, { useState } from "react";
import Webcam from "react-webcam";
import download from 'downloadjs';

const videoConstraints = {
    width: 480,
    height: 480,
    // facingMode: "user"
    facingMode: { exact: "environment" }
  };
  
const WebcamCapture = () => {
const webcamRef = React.useRef(null);
const [capturedImage, setCapturedImage] = useState(null);

const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
}, [webcamRef]);

const saveImage = () => {
    let cap = capture()
    if (capturedImage) {
    // Convert base64 image data to binary
    const binaryImageData = atob(capturedImage.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryImageData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryImageData.length; i++) {
        uint8Array[i] = binaryImageData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'image/jpeg' });

    // Download the image
    download(blob, 'captured_image.jpg', 'image/jpeg');
    }
};

return (
    <div>
    <Webcam
        audio={false}
        height={480}
        screenshotFormat="image/jpeg"
        width={480}
        videoConstraints={videoConstraints}
        ref={webcamRef}
    />
    <button onClick={saveImage}>capture</button>
    </div>
);
};
  


export default WebcamCapture;