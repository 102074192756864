import './App.css';
import WebcamCapture from './components/video_stream/video_streaming';
import VideoCapture from './components/video_recording/video_record';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


function App() {
  return(
    <Router>
      <Routes>
        <Route path='/' element={<VideoCapture />} ></Route>
        <Route path='/test' element={<WebcamCapture />} ></Route>
      </Routes>
    </Router>
  )
}

export default App;