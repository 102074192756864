import React, { useState, useRef } from 'react';

const VideoCapture = () => {
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
  
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [mediaStream, setMediaStream] = useState(null);
    const [facingMode, setFacingMode] = useState('user'); // Initialize with 'user' (front camera)
  
    const toggleFacingMode = () => {
        console.log("facingMode", facingMode)
      // Toggle between 'user' (front camera) and 'environment' (back camera)
      setFacingMode(facingMode === 'user' ? 'environment' : 'user');
    };
  
    const startCapture = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 480, height: 480, facingMode }, // Dynamically set facingMode
        audio: true,
      });
  
      videoRef.current.srcObject = stream;
  
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setRecordedChunks((prev) => prev.concat(e.data));
        }
      };
      mediaRecorderRef.current.start();
  
      setCapturing(true);
      setMediaStream(stream);
    };
  
    const stopCapture = () => {
      mediaRecorderRef.current.stop();
      mediaStream.getTracks().forEach((track) => track.stop());
      setCapturing(false);
      setMediaStream(null);
    };
  
    const saveVideo = () => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        let x = Math.floor(Math.random() * 100000) + 1;
        a.download = `${x}.webm`;
        a.click();
        setRecordedChunks([]);
      }
    };
  
    return (
      <div>
        <video ref={videoRef} autoPlay playsInline />
        {capturing ? (
          <button onClick={stopCapture}>Stop Capture</button>
        ) : (
          <button onClick={startCapture}>Start Capture</button>
        )}
        <button onClick={toggleFacingMode}>Toggle Camera</button>
        {recordedChunks.length > 0 && (
          <button onClick={saveVideo}>Save Video</button>
        )}
      </div>
    );
  };
  
  export default VideoCapture;
  

// export default VideoCapture;
